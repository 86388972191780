div.cms-html-itme-portal-back-ground{
   background-color:rgba(0,0,0,0.3);
   position: fixed;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   z-index: 9998;
}

div.cmsPage-popup-page-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    z-index: 9999;
    max-width: 320px;
    width: 100%;
    @include media-breakpoint-up(sm){
        max-width: 970px;
    }
}

// div.cmsPage.cmsPage--popup-page{
//     box-shadow: 2px 2px 4px #ebebeb;
// }

div.cmsPage.cmsPage--PopupPage {
    max-height: 85vh;
    overflow: auto;
}

div.cms-html-itme-portal-close-btn{
    text-align: right;
    svg{
        margin: 0.2rem;
        background-color: white;
        width: 40px;
        height: 40px;
        margin-right: 0px;
        border-radius: 50%;
        cursor: pointer;
    }
}

div.cmsPage-popup-error{
    background-color: white;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

body.cmsedit.popup-page-edit-mode{
    header#top, nav.bottomMenu, .common-footer-image-panel, .common-footer{
        display: none;
    }
}