@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar{
  .htmlPanel{
    p{
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

form.cart-shipping{
  input[type='date']{
    position: relative;
    &::-webkit-calendar-picker-indicator{
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }
  }
}

div.pickup-time-date-msg{
  font-size: 0.7rem;
  div.pickup-time-date-msg-inner{
    padding-left: 10px;
    div.error-msg{
      display: none;
    }
    div.error-msg.on{
      color: red;
      margin-bottom: 0.5rem;
      display: block;
    }
  }
}

div.grecaptcha-badge{
  z-index: 101;
}

div.recapthca-container > div > div > div{
  margin: auto;
}

.react-datepicker-wrapper{
  display: block !important;
}